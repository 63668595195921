import { message } from "antd";
import {
  getFacilities,
  getFeatureFlags,
  getUserErrorMessage,
  loginUser
} from "client/services/api";
import * as apiIndependentFunctions from "client/services/apiFunctions";
import { openNotificationWithIcon } from "client/utils";
import { LicenseState, SessionStorageKeys } from "common/enums";
import { FacilityType } from "common/types";
import { reduce } from "lodash";
import queryString from "query-string";
import React from "react";

import { AuthPageSubTitle, PageHeader, PageTitleWrapper } from "../../styles";
import { AuthPageTemplate } from "../Common/AuthPageTemplate";
import CleenLogo from "../Common/CleenLogo";
import FacilityList, { FacilityGroupType } from "./components/FacilityList";
import { LoginForm } from "./components/LoginForm/LoginForm";

type State = {
  pageLoading: boolean;
  isLoading: boolean;
  listFacilities: boolean;
  facilities: FacilityType[];
  facilityGroups: FacilityGroupType[];
  ssoEnabled: boolean;
};

type PropsType = {
  handleLogin: () => Promise<void>;
};

const pageTitle = (
  <PageTitleWrapper>
    <PageHeader>Sign in to </PageHeader>
    <CleenLogo style={{ marginBottom: "2px" }} width={123} height={28} />
  </PageTitleWrapper>
);

const pageSubTitle = <AuthPageSubTitle>Enter your details below</AuthPageSubTitle>;

export default class SignInComponent extends React.Component<PropsType, State> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      pageLoading: false,
      isLoading: false,
      listFacilities: false,
      facilities: [],
      facilityGroups: [],
      ssoEnabled: false
    };
  }

  setLoading = (isLoading: boolean) => {
    this.setState({
      isLoading
    });
  };

  componentDidMount() {
    this.setState(
      {
        pageLoading: true
      },
      async () => {
        try {
          const featureFlags = await getFeatureFlags();
          const ssoEnabled = featureFlags.SSO;

          if (ssoEnabled) {
            const { sso } = queryString.parse(window.location.search);
            if (sso === "true") {
              await this.getFacilities();
            }
          }

          this.setState({
            ssoEnabled
          });
        } catch (error) {
          if (error && error.message) {
            openNotificationWithIcon("error", error.message, "");
          }
        } finally {
          this.setState({
            pageLoading: false
          });
        }
      }
    );
  }

  setFacility = async (facility: FacilityType) => {
    /**
     * This function is called when the user has successfully logged in and:
     *  - User has to manually select the facility to use the app
     *  - Automatic facility selection as in case of single facility
     */
    sessionStorage.setItem(SessionStorageKeys.CURRENT_FACILITY, facility.id);
    sessionStorage.setItem(SessionStorageKeys.PRODUCT_TYPE, facility.productType);
    sessionStorage.setItem(SessionStorageKeys.FACILITY_TIER, "lite");
    //Add API and Add product forms need to know the structure of the form
    //Unlike Edit, data might not be available for the Add form to guess the structure of the data
    //Hence, we need to store the strucutre in a globally accessible place
    const {
      ADDITIONAL_API_PROPERTIES,
      ADDITIONAL_PRODUCT_PROPERTIES,
      ADDITIONAL_PRODUCTION_PROPERTIES,
      ADDITIONAL_INTERMEDIATE_PROPERTIES
    } = SessionStorageKeys;
    sessionStorage.setItem(
      ADDITIONAL_API_PROPERTIES,
      JSON.stringify(facility[ADDITIONAL_API_PROPERTIES])
    );
    sessionStorage.setItem(
      ADDITIONAL_INTERMEDIATE_PROPERTIES,
      JSON.stringify(facility[ADDITIONAL_INTERMEDIATE_PROPERTIES])
    );
    sessionStorage.setItem(
      ADDITIONAL_PRODUCT_PROPERTIES,
      JSON.stringify(facility[ADDITIONAL_PRODUCT_PROPERTIES])
    );
    sessionStorage.setItem(
      ADDITIONAL_PRODUCTION_PROPERTIES,
      JSON.stringify(facility[ADDITIONAL_PRODUCTION_PROPERTIES])
    );

    /**
     * The following call sets the login state
     * based upon which the user is redirected
     */
    await this.props.handleLogin();
  };

  handleFacilities = async () => {
    const { facilities } = this.state;

    if (facilities.length > 1) {
      this.setState({
        listFacilities: true
      });
    } else {
      await this.setFacility(facilities[0]);
    }
  };

  getFacilities = async () => {
    try {
      const facilities = await getFacilities();
      const facilityGroups = {};
      for (const facility of facilities) {
        if (facilityGroups[facility.facilityGroup.name]) {
          facilityGroups[facility.facilityGroup.name].push(facility);
        } else {
          facilityGroups[facility.facilityGroup.name] = [];
          facilityGroups[facility.facilityGroup.name].push(facility);
        }
      }

      const groups: FacilityGroupType[] | string[] = Object.keys(facilityGroups).map(group => ({
        name: group,
        facilities: facilityGroups[group]
      }));

      this.setState({
        facilities,
        facilityGroups: groups
      });
      await this.handleFacilities();
    } catch (error) {
      message.warning(error.message);
      this.setLoading(false);
    }
  };

  login = async (userId: string, password?: string) => {
    this.setLoading(true);
    const { ssoEnabled } = this.state;

    try {
      if (ssoEnabled) {
        sessionStorage.setItem(SessionStorageKeys.USER_ID, userId);
        window.location.href = `${window.location.origin}/api/v2/auth/sso/azure/login?u=${userId}`;
      } else {
        const license = await loginUser(userId, password);

        const nonGenuineLicenseHashMap = reduce(
          license,
          (acc, { facilityId, ...rest }) => {
            if (rest.state === LicenseState.GENUINE || rest.workflow === "none") {
              return acc;
            }
            return { ...acc, [facilityId]: { ...rest } };
          },
          {}
        );

        sessionStorage.setItem(
          SessionStorageKeys.NON_GENUINE_LICENSE_INFORMATION,
          JSON.stringify(nonGenuineLicenseHashMap)
        );

        /**
         * If the URL has a hidden URL, check below
         * The login link could be a redirection link. If so, as soon as the user logs in
         * the facility must be set and he must be redirected to the path given with the URL
         */
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("redirect") && urlParams.get("redirect") === "true") {
          const path = urlParams.get("path");
          const facId = urlParams.get("facilityId");
          if (path && facId) {
            const facilities = await getFacilities();
            const facDetails = facilities.filter(f => f.id === facId);
            if (facDetails && facDetails.length) {
              const productType = facDetails[0].productType;
              const additionalApiFields =
                facDetails[0][SessionStorageKeys.ADDITIONAL_API_PROPERTIES];
              const additionalIntermediateFields =
                facDetails[0]?.[SessionStorageKeys.ADDITIONAL_INTERMEDIATE_PROPERTIES];

              sessionStorage.setItem(SessionStorageKeys.CURRENT_FACILITY, facId);
              sessionStorage.setItem(SessionStorageKeys.FACILITY_TIER, "lite");
              sessionStorage.setItem(SessionStorageKeys.PRODUCT_TYPE, productType);
              sessionStorage.setItem(
                SessionStorageKeys.ADDITIONAL_API_PROPERTIES,
                JSON.stringify(additionalApiFields)
              );
              sessionStorage.setItem(
                SessionStorageKeys.ADDITIONAL_INTERMEDIATE_PROPERTIES,
                JSON.stringify(additionalIntermediateFields)
              );
              // Redirect User to the URL
              window.location.href = `/${path}`;
            }
          }
        }
        await this.getFacilities();
        // await this.getFacilityGroups();
      }
    } catch (e) {
      this.setLoading(false);
      let errorMessage = "";
      if (apiIndependentFunctions.isUserError(e)) {
        errorMessage = getUserErrorMessage(e);
        if (this.redirectUserbasedOnError(e)) {
          setTimeout(() => {
            window.location.href = e.message;
          }, 2000);
        }
      } else {
        errorMessage = e && e.message;
      }
      message.config({ maxCount: 1 });
      message.warning(errorMessage);
    }
  };

  redirectUserbasedOnError = (error: any) => {
    const { name } = error;
    return name === "ChangePasswordOnFirstLogin" || name === "ChangePasswordOnPasswordExpiry";
  };

  render() {
    const { listFacilities, facilityGroups, pageLoading, ssoEnabled } = this.state;

    return (
      <React.Fragment>
        {listFacilities ? (
          <FacilityList facilityGroups={facilityGroups} setFacility={this.setFacility} />
        ) : (
          <AuthPageTemplate
            isLoading={pageLoading}
            title={pageTitle}
            subTitle={pageSubTitle}
            pageForm={
              <LoginForm
                ssoEnabled={ssoEnabled}
                onSubmit={this.login}
                isLoading={this.state.isLoading}
              />
            }
          />
        )}
      </React.Fragment>
    );
  }
}
